import React from 'react';
import { Text, TextVariant } from '@naf/text';
import { Grid } from '@naf/grid';
import { ArticleReference } from '../../../../../../types/articleReferenceType';
import { NewsCard } from './NewsCard';

interface Props {
  articles?: ArticleReference[];
}

export const News = ({ articles }: Props) => (
  <div>
    <Text variant={TextVariant.Header2}>Aktuelle saker fra NAF</Text>
    {articles && (
      <Grid>
        {articles.map((article) => (
          <NewsCard article={article} key={article.id} />
        ))}
      </Grid>
    )}
  </div>
);
